import "../styles/laws.scss";
import { Helmet } from "react-helmet";

const Privacy = () => {
    return (
        <div className="container law">
            <Helmet>
                <title>Privacy Policy | Munix</title>
            </Helmet>
            <div className="content">
                <h1>Privacy Policy - Munix</h1>
                <h4>
                    Effective Date: 17.07.2023
                    <br />
                    Last Updated: 18.08.2023
                </h4>
                <p>
                    At Munix, we are committed to protecting the privacy and data of our users. This Privacy Policy explains how we collect, use, and safeguard user data when you use Munix ("the bot"). By using Munix, you agree to the terms outlined in this Privacy Policy.
                </p>

                <h4>1. Data Collection:</h4>
                <ol>
                    <li>Munix does not automatically collect any personal information from users without their consent.</li>
                    <li>We only collect data that is explicitly provided by users through commands while using the bot.</li>
                </ol>


                <h4>2. Types of Data Collected:</h4>
                <ol>
                    <li>User-Provided Data: This includes information that users voluntarily provide when interacting with the bot, such as music preferences or playlist names.</li>
                    <li>Server Data: Munix may store data related to your Discord server settings, such as prefix preferences or channel configurations, to enhance the bot's functionality.</li>
                </ol>

                <h4>3. Use of Data:</h4>
                <ol>
                    <li>Munix uses the collected data to provide its services, including playing music, managing playlists, and customizing user experiences within Discord servers.</li>
                    <li>The data is solely used for the purpose of delivering the requested features and functionalities of the bot.</li>
                </ol>

                <h4>4. Data Security:</h4>
                <ol>
                    <li>We take reasonable measures to protect user data from unauthorized access, disclosure, or alteration.</li>
                    <li> Data is stored securely on servers with restricted access, and we employ encryption techniques where appropriate.</li>
                </ol>

                <h4>5. Data Deletion:</h4>
                <ol>
                    <li>Users have the option to delete specific data they provided through Munix commands. This includes removing playlists or preferences that were saved during bot interactions.</li>
                    <li>Deleted data will be permanently removed from our servers.</li>
                </ol>

                <h4>6. Third-Party Services:</h4>
                <ol>
                    <li>Munix may interact with third-party services to provide certain features. Users should review the privacy policies of these third-party services for information on their data handling practices</li>
                </ol>

                <h4>7. Children's Privacy</h4>
                <ol>
                    <li>Munix is not intended for use by children under the age of 13. We do not knowingly collect personal information from children</li>
                </ol>

                <h4>8. Updates to the Privacy Policy:</h4>
                <ol>
                    <li>This Privacy Policy may be updated from time to time. Users will be notified of any significant changes</li>
                </ol>

                <h4>9. Contact:</h4>
                <ol>
                    <li>If you have any questions or concerns regarding this Privacy Policy or your data in Munix, please contact us.</li>
                </ol>

                <p style={{marginTop: "30px"}}>
                    By using Munix, you acknowledge that you have read, understood, and agreed to this Privacy Policy. If you do not agree with this policy, please refrain from using the bot.
                </p>
            </div>
        </div>
    );
}

export default Privacy;