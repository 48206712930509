import { Helmet } from "react-helmet";
import '../styles/landing.scss';
import Feature from "../components/landing/Feature";
import TimerIcon from "../assets/icons/timer.png"
import HeadPhonesIcon from "../assets/icons/headphones.png"
import SettingsIcon from "../assets/icons/settings.png"
import PayWallIcon from "../assets/icons/paywall.png"
const Landing = () => {
    return (
        <div className="landing">
            <Helmet>
                <title>Munix | Next-Gen Discord Music</title>
            </Helmet>
            <div className="container">
                <div className="header">
                    <h1>Munix</h1>
                    <h2>redefining music bots <span className="color">now</span>, not <span className="color">later</span></h2>
                    <h3>Say goodbye to mediocre sound and costly premium subscriptions. <br />Experience the future of music today and join the revolution!</h3>
                    <a href="#" className="invite">Invite Munix NOW!</a>
                </div>
                <div className="features">
                    <div className="g g1">
                        <Feature icon={TimerIcon} headline="Reliable" text="Never miss a beat with Munix’s 24/7 uptime." />
                        <Feature icon={HeadPhonesIcon} headline="High-Quality" text="Stream your selected audio in the premium quality for free." />
                    </div>
                    <div className="g g2">
                        <Feature icon={SettingsIcon} headline="Easy Control" text="Intuitive interface; control everything with simple commands." />
                        <Feature icon={PayWallIcon} headline="No Paywall" text="Enjoy Munix commands without anything hiding behind a paywall!" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Landing;