import React from "react";

const Feature: React.FC<{
    icon: string,
    headline: string,
    text: string
}> = ({ icon, headline, text }) => {

    return (
        <div className="feature">
            <img src={icon} alt="Icon" className="icon" />
            <h4 className="head">{headline}</h4>
            <p className="desc">{text}</p>
        </div>
    )
}

export default Feature;