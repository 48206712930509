import { Helmet } from "react-helmet";
import '../styles/premium.scss';
import Check from '../assets/icons/check.svg'

const Premium = () => {
    return (
        <div className="premium">
            <Helmet>
                <title>Munix | Next-Gen Discord Music</title>
            </Helmet>
            <div className="container">
                <div className="header">
                    <h1>Munix <span className="color">Prime</span></h1>
                    <p>We know that not everyone can contribute financially. For those who wish to go the extra mile, the Prime tiers are available. Every cent and vote keeps Munix thriving.
                        <br /><br />
                        Thank you for your support, without all of our Prime users, it wouldn't be possible to keep Munix up and running!</p>
                </div>
                <div className="pricing">
                    <div className="cards">
                        <Card bestValue={false} title="User" desc="For your account" price="2€" features={[
                            "All Prime Benefits",
                            "Bypass all vote-locks",
                            "VIP Support",
                            "Exclusive role on our support server"
                        ]} link="https://www.patreon.com/checkout/MunixBot?rid=10003013" />
                        <Card bestValue={true} title="Custom Bot" desc="For up to 5 servers" price="5€" features={[
                            "All Prime Benefits",
                            "Usable in up to 5 servers",
                            "Bypass all vote-locks",
                            "Custom bot name, avatar and status",
                            "Customize the embed color",
                            "Even higher music quality",
                            "98% Uptime SLA (yearly)",
                            "VIP Support",
                            "Exclusive role on our support server"
                        ]} link="https://www.patreon.com/checkout/MunixBot?rid=10003048" />
                        <Card bestValue={false} title="Server" desc="For your account and one server of your choice" price="3€" features={[
                            "1 Prime server",
                            "All Prime Benefits",
                            "Bypass all vote-locks",
                            "VIP Support",
                            "Exclusive role on our support server"
                        ]} link="https://www.patreon.com/checkout/MunixBot?rid=10003027" />
                    </div>
                    <Card small={true} bestValue={false} title="All You Own" desc="For as many servers as you own" price="10€" features={[
                        "All Prime Benefits",
                        "As many prime servers as you own",
                        "Bypass all vote-locks",
                        "VIP Support",
                        "Exclusive role on our support server"
                    ]} link="https://www.patreon.com/checkout/MunixBot?rid=10003035" />
                </div>
            </div>
        </div>
    )
}

const Card: React.FC<{
    bestValue: boolean,
    small?: boolean,
    title: string,
    desc: string,
    price: string,
    features: string[],
    link: string
}> = ({
    bestValue,
    small = false,
    title,
    desc,
    price,
    features,
    link
}) => {

        const middleIndex = Math.ceil(features.length / 2);

        return (
            <div className={small ? "card small" : "card"}>
                {/* {bestValue &&
                    <p className="bestValue">Best Value</p>
                } */}
                <div className={bestValue ? "bgCard best" : "bgCard"}>
                    <div className="info">
                        <p className="price top">{price}</p>
                        <p className="title">{title}</p>
                        <p className="desc">{desc}</p>
                        <p className="price bottom">{price}</p>
                        <a href={link} className="firstBTN">Learn More</a>
                    </div>
                    <div className="features">
                        <div className="col">
                            {features.slice().splice(0, middleIndex).map((feature, i) =>
                                <div className="feature">
                                    <img src={Check} alt="Check" className="check" />
                                    <p className="text">{feature}</p>
                                </div>
                            )}
                        </div>
                        <div className="col">
                            {features.slice().splice(-middleIndex).map((feature, i) =>
                                <div className="feature">
                                    <img src={Check} alt="Check" className="check" />
                                    <p className="text">{feature}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <a href={link} className="secondBTN">Learn More</a>
                </div>
            </div>
        )
    }

export default Premium;