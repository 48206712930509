import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Landing from './views/Landing';
import NotFound from './views/404';
import Backdrop from './components/Backdrop';
import Premium from './views/Premium';
import Privacy from './views/Privacy';
import TOS from './views/TOS';

function App() {
  return (
    <div className="app">
      <Backdrop />
      <Navbar />
      <div className="content">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/premium" element={<Premium />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<TOS />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
