import React from "react";
import { Helmet } from "react-helmet";

const NotFound: React.FC = () => {
  return (
    <div className="page" style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "60vh",
    }}>
        <Helmet>
            <title>404 | Munix</title>
        </Helmet>
      <h1>404</h1>
      <p>Page not Found</p>
    </div>
  );
}

export default NotFound;