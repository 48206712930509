import "../styles/laws.scss";
import { Helmet } from "react-helmet";

const TOS = () => {
    return (
        <div className="container law">
            <Helmet>
                <title>Privacy Policy | Munix</title>
            </Helmet>
            <div className="content">
                <h1>Terms of Service - Munix</h1>
                <h4>
                    Effective Date: 17.07.2023
                    <br />
                    Last Updated: 18.08.2023
                </h4>
                <p>
                    By using Munix ("the bot"), you agree to the following terms and conditions:
                </p>

                <h4>1. Usage</h4>
                <ol>
                    <li>Munix is intended for personal, non-commercial use only.</li>
                    <li>You must comply with Discord's Terms of Service (https://discord.com/terms) and Community Guidelines (https://discord.com/guidelines) while using the bot.</li>
                </ol>

                <h4>2. Copyright:</h4>
                <ol>
                    <li>You are responsible for ensuring that you have the necessary rights and permissions to play music using Munix in your Discord server.</li>
                    <li>Do not use Munix to play copyrighted music without proper authorization.</li>
                </ol>

                <h4>3. Abuse and Misuse:</h4>
                <ol>
                    <li>Do not use Munix to spam or harass other users.</li>
                    <li>Do not use Munix for malicious purposes or to distribute harmful content.</li>
                    <li>Do not engage in any activities that violate Discord's Terms of Service or Community Guidelines.</li>
                </ol>

                <h4>4. Limitation of Liability:</h4>
                <ol>
                    <li>The developers of Munix are not responsible for any damages or losses incurred while using the bot.</li>
                    <li>The developers of Munix do not guarantee uninterrupted or error-free service.</li>
                </ol>

                <h4>5. Changes to Munix and Terms of Service:</h4>
                <ol>
                    <li>The developers of Munix reserve the right to modify or discontinue the bot at any time without notice.</li>
                    <li> Changes to the Terms of Service will be effective upon posting the updated version.</li>
                </ol>

                <h4>6. Privacy:</h4>
                <ol>
                    <li>Munix may collect and store certain user data to provide its services. Please refer to the Privacy Policy for more details on how user data is handled.</li>
                </ol>

                <h4>7. Indemnification:</h4>
                <ol>
                    <li>By using Munix, you agree to indemnify and hold harmless the developers of Munix from any claims, damages, or liabilities arising out of your use of the bot.</li>
                </ol>

                <h4>8. Governing Law:</h4>
                <ol>
                    <li>These Terms of Service shall be governed by and construed in accordance with the laws of [Your Jurisdiction].</li>
                </ol>

                <h4>9. Contact:</h4>
                <ol>
                    <li>If you have any questions or concerns regarding Munix or these Terms of Service, please contact support@munix.one.</li>
                </ol>

                <p style={{ marginTop: "30px" }}>
                By using Munix, you acknowledge that you have read, understood, and agreed to these Terms of Service. If you do not agree with these terms, please refrain from using the bot.                </p>
            </div>
        </div>
    );
}

export default TOS;