import React from "react";

const Backdrop = () => {

    return (
        <div className="blurBackdrop" style={{
          height: window.innerHeight,
          width: window.innerWidth  
        }}>
            { new Array(5).fill(null).map((_, i) => 
                <div className="point" style={{
                    left: `${Math.random()*100}%`,
                    top: `${Math.random()*100}%`,
                }}></div>
            )}
            <div className="blur"></div>
        </div>
    )
}

export default Backdrop;