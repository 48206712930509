import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/logo.png"

const Footer = () => {

    const noMarginPages = ['/', '/blog/2023/day-of-hope']
    let location = useLocation();
    const [hasMargin, setHasMargin] = React.useState(true);

    React.useEffect(() => {
        if (noMarginPages.includes(window.location.pathname)) {
            setHasMargin(false);
        } else {
            setHasMargin(true);
        }
    }, [location])

    return (
        <footer className="footer" style={{
            marginTop: hasMargin ? "50px" : 0
        }}>
            <div className="container">
                <div className="cols">
                    <div className="col main">
                        <img src={Logo} alt="Munix Logo" className="logo" />
                        <div className="text">
                            <h3>Munix</h3>
                            <p>Munix is the next-generation Music Bot for discord. <br />
                                support@munix.one</p>
                        </div>
                    </div>
                    <div className="links">
                        <div className="col">
                            <div className="head">Legal</div>
                            <Link to="/privacy">Privacy Policy</Link>
                            <Link to="/terms">Terms of Service</Link>
                            <Link to="/refunds">Refund Policy</Link>
                        </div>
                        <div className="col">
                            <div className="head">Links</div>
                            <a href="/impressum">Vote on top.gg</a>
                            <a href="/datenschutz">Vote on DC Botlist</a>
                            <a href="/datenschutz">Premium Plans</a>
                            <a href="/datenschutz">Support Server</a>
                        </div>
                    </div>
                </div>

                <div className="copyright">
                    <p>&copy; {new Date().getFullYear()} Munix - Website by <strong><a href="https://harmonicweb.de">Harmonic Web</a></strong> </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;