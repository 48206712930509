import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/logo.png";
import Menu from '../assets/icons/menu.svg';
import MenuDark from '../assets/icons/menuDark.svg'


const Navbar = () => {

    const links = [
        { name: "Start", path: "/" },
        { name: "Invite", path: "/invite" },
        { name: "Support", path: "/support" },
    ]

    const notTranslusentPages = ['']
    let location = useLocation();
    const [scrolled, setScrolled] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {
        setExpanded(false);
        window.scrollTo(0, 0);
        if (notTranslusentPages.includes(window.location.pathname)) {
            setScrolled(true);
            document.onscroll = () => { }
        } else {
            setScrolled(false);

            document.onscroll = () => {
                if (window.scrollY > 20) {
                    setScrolled(true);
                } else {
                    setScrolled(false);
                }
            }
        }

    }, [location])

    return (
        <nav className={`navbar ${(scrolled || expanded) && "scrolled"}`} style={{
        }}>
            <div className="backdrop"></div>
            <div className="container">
                <div className="static">
                    <Link className="logo" to="/">
                        <img src={scrolled || expanded ? Logo : Logo} alt="Munix Logo" />
                        <h1>Munix</h1>
                    </Link>
                    <button className="burgerMenu" onClick={() => setExpanded(state => !state)}>
                        <img src={scrolled || expanded ? Menu : Menu} alt="Menu" />
                    </button>
                </div>
                <ul className={`links ${expanded && 'expanded'}`}>
                    {links.map(link =>
                        <li>
                            <Link to={link.path} className={location.pathname === link.path ? 'active' : ''}>{link.name}</Link>
                        </li>
                    )}
                </ul>
                <Link className={`contactButton ${expanded && 'expanded'}`} to="/premium">
                    Premium
                </Link>
            </div>

        </nav>
    )
}

export default Navbar;